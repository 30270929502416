import React from "react";
import { Redirect } from "react-router-dom";

import sessionRoutes from "./views/sessions/SessionRoutes";
import messagesRoute from './views/messages/MessagesRoute'
import booksRoute from "./views/Book/BooksRoutes";

export const BaseAdminUrl = '/admin';

const redirectRoute = [
  {
    path: `/` ,
    exact: true,
    component: () => <Redirect to={`/session/signin`} />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to={`/session/404`} />
  }
];

const routes = [
  ...messagesRoute,
  ...booksRoute,
  ...sessionRoutes,
  ...redirectRoute,
  ...errorRoute
];

export default routes;

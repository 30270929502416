import axios from 'axios'
import {BaseUrl} from "../utils";


/* you can change the base url from here */
//========================================================================================//

async function List(url, moduleName, props, params) {
    // console.log(header)
    await axios({
        method: 'get',
        url: BaseUrl+url,
        params: params
    }).then(function (result) {
        console.log(result)
        if(result.status == 200) {
            props.addList(result.data, moduleName);
            return result.data
        }else{
            props.addList('notFound', moduleName);
        }
    }).catch(function (error) {
        console.log('error', error);
        if(error.message.search('401') !== -1){
            console.log(error)
            localStorage.removeItem("auth_admin");
            localStorage.removeItem("jwt_admin_token");
            delete axios.defaults.headers.common["Authorization"];
            props.addList('notFound', moduleName);
            props.history.replace('/session/signin')
        }
        props.addList('notFound', moduleName);
        return error
    });
}

/*this function used to send some data to server and catch lists then it returns lists to redux store :) */
//=======================================================================================================//

async function Post(url, Data, params, props, message, moduleName, timing= false) {
    await axios({
        method: 'post',
        url: BaseUrl+ url,
        headers: {},
        data: Data,
        params: params,
        onUploadProgress: function (progressEvent) {
            if(timing) {
                const {loaded, total} = progressEvent;
                props.loadPercent(Math.floor((loaded * 100) / total));
            }
        },
    }).then(function (result) {
        console.log('dataaaa',result)
        props.addPost(result, false, message, moduleName)
    }).catch(function (error) {
        console.log('error', error)
        props.addPost({data: 'error!'}, error, message, moduleName)
    }).finally(function (final) {
        if(timing) {
            props.loadPercent(null);
        }
    })
}

/*this function just do post with our restfull api */
//======================================================================================================//

async function Patch(moduleName, url, Data){
    await axios({
        method: 'patch',
        url: BaseUrl+ url,
        headers: {},
        data: Data
    }).then(function (result) {
        console.log('dataaaa',result)
    }).catch(function (error) {
        console.log('error', error)
    });
}

/*this function will update your data in server */
//=======================================================================================================//

async function Delete(url, params, moduleName, props, message){

    await axios({
        method: 'delete',
        url: BaseUrl+ url,
        headers: {},
        params: params,
    }).then(function (result) {
        console.log('dataaaa',result);
        props.addDelete(result, message, moduleName);
    }).catch(function (error) {
        console.log('error', error);
        props.addDelete(error, message, moduleName);
    });
}

/* this function will delete an object in server */
//=======================================================================================================//

async function Retrieve(url, moduleName, props, params){
    await axios({
        method: 'get',
        url: BaseUrl+url,
        header:{},
        params: params
    }).then(function (result) {
        console.log('dataaaa',result.data)
        props.addRetrieve(result.data, moduleName);
        return result.data
    }).catch(function (error) {
        console.log('error', error)
        if(error.message.search('401') !== -1){
            console.log(error)
            localStorage.removeItem("auth_admin");
            localStorage.removeItem("jwt_admin_token");
            delete axios.defaults.headers.common["Authorization"];
            if(props.history) {
                props.history.replace('/session/signin')
            }
        }else{
            props.addRetrieve(error, moduleName);
        }
        return error
    });
}

/* this function will catch only one object's data */
//=======================================================================================================//

export {List, Post, Patch, Delete, Retrieve}

/* developer of this data manager is shyn99 don't use it without permission! :))) */

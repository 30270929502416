import React, { Component } from "react";
import {
  Card,
  Grid,
  Button,
  withStyles,
  CircularProgress
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import {Redirect, withRouter} from "react-router-dom";
import persianJs from 'persianjs'
import { loginWithEmailAndPassword } from "../../redux/actions/LoginActions";
import localStorageService from "../../services/localStorageService";
import {dimLoading, startSnack} from "../../redux/actions/WidgetActions";
import {NumberInput} from "react-hichestan-numberinput";
import {Post} from "../../../libs/DataManager";
import {addPost} from "../../redux/actions/DataActions";


const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

class SignIn extends Component {
  state = {
    username: "",
    password: "",
    agreement: "",
    redirect:false,
    page: 1
  };
  handleChange = event => {
    event.persist();
    this.setState({
      [event.target.name]: event.target.value
    });
  };
  handleChangeUser = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.login.loading !== prevProps.login.loading && this.props.login.error !== ''){
      this.props.startSnack({color: 'error', message: this.props.login.error})
    }
  }

  componentDidMount() {
    if(localStorageService.getItem("auth_admin")){
      this.setState({redirect: true})
    }else{
      this.setState({redirect: false})
    }
  }

  handleFormSubmit = async event => {
    this.props.dimLoading();
    await Post('/oauth2/send-code', {countryCode:"+98", phone: this.state.username}, null, this.props, {success: 'کدتایید را وارد کنید', error: 'شماره صحیح نمیباشد'}, 'SendCode', false);
    if(this.props.data.result['SendCode']){
      if(this.props.data.result['SendCode'].status===200){
        if(this.props.data.result['SendCode'].data){
          this.setState({page: 2});
        }
      }else{
        this.props.startSnack({color:'error', message: 'شماره را با دقت وارد کنید.'})
      }
    }
  };

  handleFormLastSubmit= e =>{
    this.props.loginWithEmailAndPassword( this.state.username, persianJs(this.state.password).switchKey().toString());
  }

  render() {
    let { username, password, redirect } = this.state;
    let { classes } = this.props;
    if(redirect){
      return <Redirect to={`/books`}/>
    }
    return (
      <div className="signup flex flex-center w-100 h-100vh">
        <div className="p-8">
          <Card className="signup-card position-relative y-center">
            <Grid container>
              <Grid item lg={5} md={5} sm={5} xs={12}>
                <div className="p-32 flex flex-center flex-middle h-100">
                  <img className='w-75-' src="/assets/images/logo.png" alt="" />
                </div>
              </Grid>
              <Grid item lg={7} md={7} sm={7} xs={12}>
                <div className="p-36 h-100 bg-light-gray position-relative">
                  {this.state.page === 1 &&
                    <ValidatorForm ref="form" onSubmit={this.handleFormSubmit}>
                      <TextValidator
                          className="mb-24 w-100 "
                          variant="outlined"
                          label="شماره همراه"
                          onChange={this.handleChangeUser}
                          type="number"
                          name="username"
                          value={username}
                          InputProps={{
                            inputComponent: NumberInput,
                            inputProps: {maxLength: 12},
                          }}
                          validators={["required"
                          ]}
                          errorMessages={[
                            "this field is required",
                          ]}
                      />
                      <div className="flex flex-middle mb-8">
                        <div className={classes.wrapper}>
                          <Button
                              variant="contained"
                              color="primary"
                              disabled={this.props.login.loading}
                              type="submit"
                          >
                            ورود
                          </Button>
                          {this.props.login.loading && (
                              <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                              />
                          )}
                        </div>
                      </div>
                    </ValidatorForm>
                  }
                  {this.state.page ===2 &&
                    <ValidatorForm ref="form" onSubmit={this.handleFormLastSubmit}>
                      <TextValidator
                          className="mb-16 w-100"
                          label="کد تایید"
                          variant="outlined"
                          onChange={this.handleChange}
                          name="password"
                          type="text"
                          value={password}
                          validators={["required"]}
                          errorMessages={["this field is required"]}
                      />
                      <div className="flex flex-middle mb-8">
                        <div className={classes.wrapper}>
                          <Button
                              variant="contained"
                              color="primary"
                              disabled={this.props.login.loading}
                              type="submit"
                          >
                            ورود
                          </Button>
                          {this.props.login.loading && (
                              <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                              />
                          )}
                        </div>
                      </div>
                    </ValidatorForm>
                  }
                </div>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  loginWithEmailAndPassword: PropTypes.func.isRequired,
  login: state.login,
  data: state.data
});
export default withStyles(styles, { withTheme: true })(
  withRouter(
    connect(
      mapStateToProps,
      { loginWithEmailAndPassword, startSnack, addPost, dimLoading }
    )(SignIn)
  )
);

import {MatxLoadable} from "../../../matx";
import {authRoles} from "../../auth/authRoles";

const Books = MatxLoadable({
    loader: () => import("./Books")
})

const BooksDetails = MatxLoadable({
    loader: () => import("./details/BookAddEdit")
})

const booksRoute = [
    {
        exact: true,
        path: `/books`,
        component: Books,
        auth: authRoles.messages,
    },{
        exact: true,
        path: `/books/details`,
        component: BooksDetails,
        auth: authRoles.messages,
    },{
        exact: true,
        path: `/books/details/:id`,
        component: BooksDetails,
        auth: authRoles.messages,
    },
]

export default booksRoute;

import axios from "axios";
import localStorageService from "./localStorageService";
import {BaseUrl} from "../../utils";

class JwtAuthService {

  // You need to send http request with email and passsword to your server in this method
  // Your server will return user object & a Token
  // User should have role property
  // You can define roles in app/auth/authRoles.js
  loginWithEmailAndPassword = async (username, password) => {
    let data= {
      countryCode: "+98",
      phone: username,
      activeCode: password
    }
    let self= this;
    await axios({
      method:'post',
      url:`${BaseUrl}/oauth2/check-code`,
      data: data
    }).then(async function (result) {
      console.log('data::', result.data);
      if(result.data.status === 'OK'){
            console.log(result.data.result);
            let user={
              userId: '1',
              role: 'ADMIN',
              displayName: result.data.result.username,
              username: result.data.result.phone,
              token: result.data.result.accessToken,
              refresh_token: result.data.result.refreshToken,
              photoURL: (result.data.result.imageAdd)?result.data.result.imageAdd:"/assets/images/user.jpg",
              access_token: result.data.result.accessToken
            };
            await self.setSession(user.token);
            await self.setUser(user);
            return user
      }
    }).catch(function (error) {
      console.log(error)
    });
  };

  // You need to send http requst with existing token to your server to check token is valid
  // This method is being used when user logged in & app is reloaded
  loginWithToken = async () => {
    let user = localStorageService.getItem("auth_admin");
    console.log('heeey:', user)
    await this.setSession(user['access_token']);
    await this.setUser(user);
    return user
  };

  logout = () => {
    this.setSession(null);
    this.removeUser();
  }

  // Set token to all http request header, so you don't need to attach everytime
  setSession =async token => {
    if (token) {
      console.log('toke:::', token);
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
      await localStorage.setItem("jwt_admin_token", token);
      await localStorage.setItem("bar_admin_token", "Bearer " + token);
    } else {
      localStorage.removeItem("jwt_admin_token");
      delete axios.defaults.headers.common["Authorization"];
    }
  };

  // Save user to localstorage
  setUser = (user) => {
    localStorageService.setItem("auth_admin", user);
  };
  // Remove user from localstorage
  removeUser = () => {
    localStorage.removeItem("auth_admin");
  }
}

export default new JwtAuthService();

import jwtAuthService from "../../services/jwtAuthService";
import FirebaseAuthService from "../../services/firebase/firebaseAuthService";
import { setUserData } from "./UserActions";
import history from "history.js";
import axios from "axios";
import localStorageService from "../../services/localStorageService";
import {BaseUrl} from "../../../utils";
import {DimLoading, StartSnack} from "./WidgetActions";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_LOADING = "LOGIN_LOADING";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const CodeHandle = 'CodeHandle'

export function loginWithEmailAndPassword( username, password ) {
  return dispatch => {
    dispatch({
      type: LOGIN_LOADING
    });

    jwtAuthService
      .loginWithEmailAndPassword(username, password)
        .then(async user => {
          console.log(user);
        if(localStorageService.getItem("auth_admin")){
            console.log(localStorageService.getItem("auth_admin"))
            dispatch(setUserData(localStorageService.getItem("auth_admin")));

            history.push({
                pathname: `/books`
            });

            return dispatch({
                type: LOGIN_SUCCESS
            });
        }
        else{
            return dispatch({
                type: LOGIN_ERROR,
                data: "نام کاربری یا رمز عبور صحیح نمیباشد"
            })
        }

      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          data: "مشکلی رخ داده‌است"
        });
      });
  };
}

export function resetPassword({ mobile }) {
    return async dispatch => {
        dispatch({
            type: DimLoading
        });

        await axios({
            method:'get',
            url: BaseUrl + '/user/forget',
            params:{phoneNum: mobile}
        }).then(function (result) {
            console.log(result);
            if(result.data.status === 'OK') {
                console.log(result);
                dispatch({
                    type: StartSnack,
                    data: {color: 'success', message: 'کد تایید با موفقیت به گوشی همراه شما ارسال شد.'}
                });
                dispatch({
                    payload: mobile,
                    type: RESET_PASSWORD
                });

            }else {
                dispatch({
                    type: StartSnack,
                    data: {color: 'error', message: 'شماره موبایل را با دقت وارد کنید.'}
                });
            }
        }).catch(function (error) {
            dispatch({
                type: StartSnack,
                data: {color: 'error', message: 'شماره موبایل را با دقت وارد کنید.'}
            });
        }).finally(function (final) {
            dispatch({
                type: DimLoading
            });
        })
      };
}

export function resetCodeHandler(code, phoneNumber) {
    return async dispatch =>{
        dispatch({
            type: DimLoading
        });
        await axios({
            method:'get',
            url: BaseUrl + '/user/forgotCode',
            params:{phoneNum: phoneNumber, code: code}
        }).then(function (result) {
            console.log(result);
            if(result.data.status === 'OK') {
                console.log(result);
                dispatch({
                    type: StartSnack,
                    data: {color: 'success', message: 'کد تایید صحیح می‌باشد.'}
                });
                dispatch({
                    payload: code,
                    type: CodeHandle
                });

            }else {
                dispatch({
                    type: StartSnack,
                    data: {color: 'error', message: 'شماره موبایل را با دقت وارد کنید.'}
                });
            }
        }).catch(function (error) {
            dispatch({
                type: StartSnack,
                data: {color: 'error', message: 'شماره موبایل را با دقت وارد کنید.'}
            });
        }).finally(function (final) {
            dispatch({
                type: DimLoading
            });
        })
    }
}

export function newPassword(password, code, phoneNumber, history) {
    return async dispatch =>{
        dispatch({
            type: DimLoading
        });
        await axios({
            method:'post',
            url: BaseUrl + '/user/resetPassword',
            params:{code: code},
            data: {password: password, phoneNum: phoneNumber}
        }).then(function (result) {
            console.log(result);
            if(result.data.status === 'OK') {
                console.log(result);
                dispatch({
                    type: StartSnack,
                    data: {color: 'success', message: 'تغییر یافت.'}
                });
                history.replace(`/session/signin`)
            }else {
                dispatch({
                    type: StartSnack,
                    data: {color: 'error', message: 'بادقت بیشتری وارد کنید'}
                });
            }
        }).catch(function (error) {
            dispatch({
                type: StartSnack,
                data: {color: 'error', message: 'بادقت بیشتری وارد کنید'}
            });
        }).finally(function (final) {
            dispatch({
                type: DimLoading
            });
        })
    }
}


export function firebaseLoginEmailPassword({ email, password }) {
  return dispatch => {
    FirebaseAuthService.signInWithEmailAndPassword(email, password)
      .then(user => {
        if (user) {
          dispatch(
            setUserData({
              userId: "1",
              role: "ADMIN",
              displayName: "Watson Joyce",
              email: "watsonjoyce@gmail.com",
              photoURL: "/assets/images/face-7.jpg",
              age: 25,
              token: "faslkhfh423oiu4h4kj432rkj23h432u49ufjaklj423h4jkhkjh",
              ...user
            })
          );

          history.push({
            pathname: "/"
          });

          return dispatch({
            type: LOGIN_SUCCESS
          });
        } else {
          return dispatch({
            type: LOGIN_ERROR,
            payload: "Login Failed"
          });
        }
      })
      .catch(error => {
        return dispatch({
          type: LOGIN_ERROR,
          payload: error
        });
      });
  };
}

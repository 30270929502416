import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Messages = MatxLoadable({
  loader: () => import("./Messages")
})

const MessagesEdit = MatxLoadable({
  loader: () => import("./details/MessagesEdit")
})

const Viewers = MatxLoadable({
  loader: () => import("./details/Viewers")
})

const NotifViewers = MatxLoadable({
  loader: () => import("./details/NotifViewers")
})

// const settings = {
//   activeLayout: "layout1",
//   layout1Settings: {
//     topbar: {
//       show: true
//     },
//     leftSidebar: {
//       show: false,
//       mode: "close"
//     }
//   },
//   layout2Settings: {
//     mode: "full",
//     topbar: {
//       show: true
//     },
//     navbar: { show: false }
//   },
//   secondarySidebar: { show: false },
//   footer: { show: false }
// };

const messagesRoute = [
  {
    exact: true,
    path: `/messages`,
    component: Messages,
    auth: authRoles.messages,
  },
  {
    path: `/messages/viewers/:id`,
    component: Viewers,
    auth: authRoles.messages,
  },
  {
    path: `/notifications/viewers/:id`,
    component: NotifViewers,
    auth: authRoles.messages,
  },
  {
    path: `/messages/AddNew`,
    component: MessagesEdit,
    auth: authRoles.messages,
  },
];

export default messagesRoute;

export const authRoles = {
  sa: ['SA'], // Only Super Admin has access
  admin: ['SA', 'ADMIN'], // Only SA & Admin has access
  courses: ['ADMIN', 'SECOND', 'THIRD', 'NAZEM'],
  coursesWithout: ['ADMIN', 'SECOND', 'THIRD'],
  messages: ['ADMIN', 'SECOND'],
  live: ['ADMIN', 'SECOND', 'FOURTH', 'NAZEM'],
  noMoney: ['SA', 'ADMIN', 'NOMONEY', 'NORMAL'], // Only SA & Admin & Editor has access
  noMoneyOnly: ['ADMIN', 'NOMONEY'],
  money: ['SA', 'ADMIN', 'MONEY'], // Everyone has access
  normal: ['ADMIN', 'NORMAL'],
  everyBody: ['ADMIN', 'MONEY', 'NOMONEY', 'NORMAL', 'SECOND', 'THIRD', 'FOURTH', 'NAZEM'],
  noStudio: ['ADMIN', 'MONEY', 'NOMONEY'],
}

// Check out app/views/dashboard/DashboardRoutes.js
// Only SA & Admin has dashboard access

// const dashboardRoutes = [
//   {
//     path: "/dashboard/analytics",
//     component: Analytics,
//     auth: authRoles.admin <----------------
//   }
// ];
import React from "react";
import {Button, Card, FormControlLabel, Icon, Input, Switch, Tooltip, MenuItem, Menu} from "@material-ui/core";
import {isMobile} from "../../../utils";
import {openDialog} from '../../../app/redux/actions/WidgetActions'
import {connect} from "react-redux";
import { CSVLink, CSVDownload } from "react-csv";
import PersianNumber from "../../../libs/PersianNumber";


const SimpleCard = ({ openDialog, children, title, subtitle,
                        Exporter= null, ExportType,
                        switchFor= null, switchProps, handleSwitch, switchDisable,
                        classname, Secondary= false, SecondaryPath ='', SecondaryIcon= 'add',
                        optional= false, optionalPath, optionalIcon,
                        AddButton= false, AddLimit= false,
                        opratorButtonTitle, opratorButtonFunc, opratorButtonIcon,
                        changePassButton=false, ChangePassTitle,
                        BanButton=false, BanTitle,
                        search= false, handleSearch,searchText, searchValue,
                        ButtonTitle, history, path,
                        logicalButton1= false, logicalButton1Icon, logicalButton1Func,
                        logicalButton2= false, logicalButton2Icon, logicalButton2Func,
                        logicalButton3= false, logicalButton3Icon, logicalButton3Func,
                        madarek= false, setMadrak,
                        self
                    }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (e) => {
        setMadrak(e.target.value);
        setAnchorEl(null);
    };
    return (
    <Card elevation={6} className={`px-24 py-20 h-100 ${classname}`}>
      <div className='mb-8 flex--row justifyCbetween'>
        <div className="card-title">{title}</div>
            {search &&
                <Input
                    className='mx-15 mb-24'
                    style={{width:'240px'}}
                    type='search'
                    value={searchValue}
                    startAdornment= {<Icon>search</Icon>}
                    placeholder = {searchText}
                    onChange={(e) => handleSearch(e)}
                />
            }
            <div>
                {(madarek && madarek.length > 0) &&
                    <div>
                        <Button aria-controls="simple-menu" variant='contained' color='primary' aria-haspopup="true" onClick={handleClick}>
                            مدارک
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {madarek.map((madrak, idx)=>{
                                return(
                                    <MenuItem value={idx} onClick={handleClose}>پرداختی:  <PersianNumber number={madrak.price} spacing={true}/>  تومان</MenuItem>
                                )
                            })}
                        </Menu>
                    </div>
                }
              {AddButton &&
                <Button
                    onClick={()=> history.push(path)}
                    className='mb-4 ml-4'
                    disabled={AddLimit}
                    variant="contained"
                    color='primary'
                    startIcon={(isMobile())?null:<Icon>add</Icon>}
                >
                      {ButtonTitle}
                </Button>
              }
              {Exporter &&
                  <CSVLink data={Exporter} filename={`${title}.csv`}>
                      <Button className='mb-4 ml-4 btn-primary' variant='contained' startIcon={(!isMobile())?<Icon>event_note</Icon>:null}>
                          {(!isMobile()) ? ExportType : <Icon>add</Icon>}
                      </Button>
                  </CSVLink>
              }
              {opratorButtonFunc &&
                <Button
                    onClick={() => opratorButtonFunc()}
                    className='mb-4 ml-4 text-white'
                    variant="contained"
                    color='secondary'
                    startIcon={(!isMobile())?<Icon>{opratorButtonIcon}</Icon>:null}
                >
                    {opratorButtonTitle}
                </Button>
              }
              {logicalButton1 &&
                <Button
                    onClick={() => logicalButton1Func(self)}
                    className='mb-4 ml-4 text-white'
                    variant="contained"
                    color='primary'
                    startIcon={(!isMobile())?<Icon>{logicalButton1Icon}</Icon>:null}
                >
                    {logicalButton1}
                </Button>
              }
              {logicalButton2 &&
                <Button
                    onClick={() => logicalButton2Func(self)}
                    className='mb-4 ml-4 text-white hoverErrorBg'
                    variant="contained"
                    color='primary'
                    startIcon={(!isMobile())?<Icon>{logicalButton2Icon}</Icon>:null}
                >
                    {logicalButton2}
                </Button>
              }
              {logicalButton3 &&
                <Button
                    onClick={() => logicalButton3Func(self)}
                    className='mb-4 ml-4 text-white'
                    variant="contained"
                    color='secondary'
                    startIcon={(!isMobile())?<Icon>{logicalButton3Icon}</Icon>:null}
                >
                    {logicalButton3}
                </Button>
              }
              {Secondary &&
                  <Button
                      onClick={()=> history.push(SecondaryPath)}
                      className='mb-4 ml-4 text-white'
                      variant="contained"
                      color='secondary'
                      startIcon={(!isMobile())?<Icon>{SecondaryIcon}</Icon>:null}
                  >
                      {Secondary}
                  </Button>
              }
                {switchFor &&
                <FormControlLabel
                    control={
                        <Switch
                            checked={switchProps}
                            onChange={(e)=> handleSwitch(e)}
                            disabled = {switchDisable}
                            value="checkedB"
                            color="primary"
                        />
                    }
                    label={switchFor}
                    labelPlacement="top"
                />
                }
            </div>
            {(changePassButton || BanButton || optional) &&
              <div>
                {optional &&
                  <Button
                      onClick={()=> history.push(optionalPath)}
                      className='mb-4 ml-4 text-white'
                      variant="contained"
                      color='primary'
                      startIcon={(!isMobile())?<Icon>{optionalIcon}</Icon>:null}
                  >
                      {optional}
                  </Button>
                }
                {changePassButton &&
                  <Button
                      onClick={(e) => openDialog({
                        title: 'تغییر رمزعبور',
                        color: '#ff9e43',
                        text: ('رمز جدید کاربر را وارد نمایید'),
                        image: require('../../../libs/images/changepass.png'),
                        accept: {name: 'changePass', text:''},
                        butColor: 'primary',
                        haveTextField: true,
                      })}
                      className='mb-4 ml-8'
                      variant="contained"
                      color='secondary'
                      style={{color:'white'}}
                      startIcon={(!isMobile())?<Icon>vpn_key</Icon>:null}
                  >
                        {ChangePassTitle}
                  </Button>
                }
                {BanButton &&
                  <Button
                    onClick={(e) => openDialog({
                      title: 'اخطار',
                      color: '#d32f2f',
                      text: (`آیا از ${BanTitle} این کاربر اطمینان دارید؟`),
                      image: require('../../../libs/images/ban.png'),
                      accept: {name: 'ban'},
                      butColor: 'primary'
                    })}
                    className='mb-4'
                    variant="contained"
                    style={{backgroundColor:'#e1004e',color:'white'}}
                    startIcon={(!isMobile())?<Icon>block</Icon>:null}
                  >
                        {BanTitle}
                  </Button>
                }
              </div>
            }
      </div>
      {children}
    </Card>
  );
};

export default (
  connect(
      null,
    {openDialog}
  )(SimpleCard)
);
